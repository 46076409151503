.spinner-container {
    position: fixed;
    top: 50%vh;
    left: 50%vw;
    transform: translate(-50%, -50%);
}

.spinner {
    display: inline-block;
    width: 78px;
    height: 78px;
    border: 5.2px solid rgba(0, 0, 0, 0.1);
    border-left-color: #7986cb;
    border-radius: 52%;
    animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}